import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { ConfigureFormValues } from '../validation';
import { useWizard } from '../../../context/WizardContext';
import { createDefaultEmailAction, createDefaultPhoneAction } from '../utils/actionHelpers';

interface ActionConfigProps {
  register: UseFormRegister<ConfigureFormValues>;
  setValue: UseFormSetValue<ConfigureFormValues>;
  errors: FieldErrors<ConfigureFormValues>;
  watch?: UseFormWatch<ConfigureFormValues>;
  trigger?: UseFormTrigger<ConfigureFormValues>;
}

export const ActionConfig: React.FC<ActionConfigProps> = ({
  register,
  setValue,
  errors,
  watch,
  trigger,
}) => {
  const { state, dispatch } = useWizard();
  const theme = useTheme();

  // Get the current action type and ensure it's initialized on component mount
  const actionType =
    watch?.('actionType') || state.draft?.stages[0]?.actions[0]?.type || 'emailCollection';

  // When component mounts, ensure form values are in sync with draft
  React.useEffect(() => {
    if (state.draft?.stages[0]?.actions[0]) {
      const action = state.draft.stages[0].actions[0];

      // Ensure action type is set properly
      setValue('actionType', action.type as 'emailCollection' | 'phoneCollection');

      // Set button label
      setValue('action.buttonLabel', action.label || '');

      // Set collection-specific fields
      if (action.type === 'emailCollection' && action.emailCollection) {
        setValue('action.instructionsText', action.emailCollection.instructionsText || '');
        setValue('action.disclaimerText', action.emailCollection.disclaimerText || '');
      } else if (action.type === 'phoneCollection' && action.phoneCollection) {
        setValue('action.instructionsText', action.phoneCollection.instructionsText || '');
        setValue('action.disclaimerText', action.phoneCollection.disclaimerText || '');
        setValue('action.validationPattern', action.phoneCollection.validationPattern || '');
      }
    }
  }, [state.draft, setValue]);

  // Function to update the draft with form field changes
  const updateDraftAction = (field: string, value: string) => {
    if (!state.draft) return;

    const currentActionType = state.draft.stages[0].actions[0].type;
    const updatedAction = { ...state.draft.stages[0].actions[0] };

    // Set the basic field (like label)
    if (field === 'buttonLabel') {
      updatedAction.label = value;
    }

    // Update the collection-specific fields
    if (currentActionType === 'emailCollection' && updatedAction.emailCollection) {
      if (field === 'instructionsText') {
        updatedAction.emailCollection = {
          ...updatedAction.emailCollection, // Preserve existing fields including disclaimerUrl
          instructionsText: value,
        };
      } else if (field === 'disclaimerText') {
        updatedAction.emailCollection = {
          ...updatedAction.emailCollection, // Preserve existing fields including disclaimerUrl
          disclaimerText: value,
        };
      }
    } else if (currentActionType === 'phoneCollection' && updatedAction.phoneCollection) {
      if (field === 'instructionsText') {
        updatedAction.phoneCollection = {
          ...updatedAction.phoneCollection, // Preserve existing fields including disclaimerUrl
          instructionsText: value,
        };
      } else if (field === 'disclaimerText') {
        updatedAction.phoneCollection = {
          ...updatedAction.phoneCollection, // Preserve existing fields including disclaimerUrl
          disclaimerText: value,
        };
      } else if (field === 'validationPattern') {
        updatedAction.phoneCollection = {
          ...updatedAction.phoneCollection, // Preserve existing fields including disclaimerUrl
          validationPattern: value,
        };
      }
    }

    const updatedDraft = {
      ...state.draft,
      stages: state.draft.stages.map((stage) => ({
        ...stage,
        actions: [updatedAction],
      })),
    };

    dispatch({
      type: 'SET_DRAFT',
      payload: updatedDraft,
    });
  };

  const handleActionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newActionType = event.target.value as 'emailCollection' | 'phoneCollection';
    setValue('actionType', newActionType);

    // Get the current draft action to preserve important values
    const currentAction = state.draft?.stages[0]?.actions[0];

    // Set appropriate default values based on action type
    if (newActionType === 'phoneCollection') {
      setValue('action.instructionsText', 'Please leave us your phone to get a discount');

      // Update the draft directly with the new action type
      if (state.draft) {
        // Use helper function for consistent defaults
        const phoneAction = createDefaultPhoneAction(
          currentAction?.label || 'Leave us your phone number',
        );

        // Preserve current values where appropriate
        const updatedAction = {
          ...phoneAction,
          order: currentAction?.order || 0,
          isFinal: currentAction?.isFinal !== undefined ? currentAction.isFinal : true,
          closeWidget: currentAction?.closeWidget !== undefined ? currentAction.closeWidget : true,
          // Preserve the specific phoneCollection values
          phoneCollection: {
            ...phoneAction.phoneCollection,
            instructionsText: 'Please leave us your phone to get a discount',
            disclaimerText:
              currentAction?.phoneCollection?.disclaimerText ||
              currentAction?.emailCollection?.disclaimerText ||
              'In order to continue accept Terms and Conditions',
            // Preserve existing disclaimerUrl if present
            disclaimerUrl:
              currentAction?.phoneCollection?.disclaimerUrl ||
              currentAction?.emailCollection?.disclaimerUrl ||
              phoneAction.phoneCollection.disclaimerUrl,
          },
        };

        updateDraftWithNewActionType(updatedAction);
      }
    } else {
      setValue('action.instructionsText', 'Please leave your email to get a discount');

      // Update the draft directly with the new action type
      if (state.draft) {
        // Use helper function for consistent defaults
        const emailAction = createDefaultEmailAction(currentAction?.label || 'Leave your email');

        // Preserve current values where appropriate
        const updatedAction = {
          ...emailAction,
          order: currentAction?.order || 0,
          isFinal: currentAction?.isFinal !== undefined ? currentAction.isFinal : true,
          closeWidget: currentAction?.closeWidget !== undefined ? currentAction.closeWidget : true,
          // Preserve the specific emailCollection values
          emailCollection: {
            ...emailAction.emailCollection,
            instructionsText: 'Please leave your email to get a discount',
            disclaimerText:
              currentAction?.emailCollection?.disclaimerText ||
              currentAction?.phoneCollection?.disclaimerText ||
              'In order to continue accept Terms and Conditions',
            // Preserve existing disclaimerUrl if present
            disclaimerUrl:
              currentAction?.emailCollection?.disclaimerUrl ||
              currentAction?.phoneCollection?.disclaimerUrl ||
              emailAction.emailCollection.disclaimerUrl,
          },
        };

        updateDraftWithNewActionType(updatedAction);
      }
    }

    // Trigger validation after changing action type
    if (trigger) {
      setTimeout(
        () => trigger(['action.buttonLabel', 'action.instructionsText', 'action.disclaimerText']),
        0,
      );
    }
  };

  // Helper function to update the draft when action type changes
  const updateDraftWithNewActionType = (updatedAction: any) => {
    if (!state.draft) return;

    const updatedDraft = {
      ...state.draft,
      stages: state.draft.stages.map((stage) => ({
        ...stage,
        actions: [updatedAction],
      })),
    };

    dispatch({
      type: 'SET_DRAFT',
      payload: updatedDraft,
    });
  };

  // Handle real-time updates to form fields
  const handleTextChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Update form values using setValue instead of relying only on register
    if (field === 'buttonLabel') {
      setValue('action.buttonLabel', value, { shouldValidate: true });
    } else if (field === 'instructionsText') {
      setValue('action.instructionsText', value, { shouldValidate: true });
    } else if (field === 'disclaimerText') {
      setValue('action.disclaimerText', value, { shouldValidate: true });
    } else if (field === 'validationPattern') {
      setValue('action.validationPattern', value, { shouldValidate: true });
    }

    // Also update draft for preview
    updateDraftAction(field, value);
  };

  return (
    <Box>
      <Typography sx={{ mb: 2, color: theme.palette.text.primary }} variant="h6">
        Action Settings
      </Typography>

      <FormControl fullWidth margin="normal">
        <Typography gutterBottom sx={{ color: theme.palette.text.primary }} variant="subtitle2">
          Action Type
        </Typography>
        <RadioGroup
          onChange={handleActionTypeChange}
          row
          sx={{ color: theme.palette.text.primary }}
          value={actionType}
        >
          <FormControlLabel
            control={<Radio />}
            label="Email Collection"
            sx={{ color: theme.palette.text.primary }}
            value="emailCollection"
          />
          <FormControlLabel
            control={<Radio />}
            label="Phone Collection"
            sx={{ color: theme.palette.text.primary }}
            value="phoneCollection"
          />
        </RadioGroup>
        <input type="hidden" {...register('actionType')} />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Typography gutterBottom sx={{ color: theme.palette.text.primary }} variant="subtitle2">
          Button Label
        </Typography>
        <TextField
          {...register('action.buttonLabel')}
          error={!!errors.action?.buttonLabel}
          FormHelperTextProps={{
            style: { color: errors.action?.buttonLabel ? undefined : theme.palette.text.secondary },
          }}
          fullWidth
          helperText={errors.action?.buttonLabel?.message}
          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
          InputProps={{ style: { color: theme.palette.text.primary } }}
          onChange={handleTextChange('buttonLabel')}
          placeholder="Leave your email"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Typography gutterBottom sx={{ color: theme.palette.text.primary }} variant="subtitle2">
          Disclaimer Text
        </Typography>
        <TextField
          {...register('action.disclaimerText')}
          error={!!errors.action?.disclaimerText}
          FormHelperTextProps={{
            style: {
              color: errors.action?.disclaimerText ? undefined : theme.palette.text.secondary,
            },
          }}
          fullWidth
          helperText={errors.action?.disclaimerText?.message}
          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
          InputProps={{ style: { color: theme.palette.text.primary } }}
          multiline
          onChange={handleTextChange('disclaimerText')}
          placeholder="In order to continue accept Terms and Conditions"
          rows={2}
        />
      </FormControl>

      {/* Show validation pattern only for phone collection */}
      {/* {actionType === 'phoneCollection' && (
        <FormControl fullWidth margin="normal">
          <Typography gutterBottom sx={{ color: theme.palette.text.primary }} variant="subtitle2">
            Phone Validation Pattern (Optional)
          </Typography>
          <TextField
            {...register('action.validationPattern')}
            error={!!errors.action?.validationPattern}
            FormHelperTextProps={{
              style: {
                color: errors.action?.validationPattern ? undefined : theme.palette.text.secondary,
              },
            }}
            fullWidth
            helperText={
              errors.action?.validationPattern?.message || 'Regular expression for phone validation'
            }
            InputLabelProps={{ style: { color: theme.palette.text.primary } }}
            InputProps={{ style: { color: theme.palette.text.primary } }}
            onChange={handleTextChange('validationPattern')}
            placeholder="^\+?[0-9\s-]{6,}$"
          />
        </FormControl> */}
      {/* )} */}
    </Box>
  );
};
