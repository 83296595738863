import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CreateControllerMutationVariables = Types.Exact<{
  input: Types.CreateControllerInputType;
}>;

export type CreateControllerMutation = {
  __typename?: 'Mutation';
  createController: {
    __typename?: 'ControllerType';
    id: string;
    name: string;
    validFrom?: string | null;
    validUntil?: string | null;
    isActive: boolean;
    url?: string | null;
    initialStageId: string;
    layout?: {
      __typename?: 'LayoutType';
      type: string;
      widget?: {
        __typename?: 'WidgetType';
        theme?: {
          __typename?: 'ThemeType';
          colors?: { __typename?: 'ThemeTypeColors'; primary?: string | null } | null;
        } | null;
        design: {
          __typename?: 'DesignType';
          minified: {
            __typename?: 'MinifiedType';
            closable?: boolean | null;
            widgetSize?: string | null;
            shape?: string | null;
            caption?: { __typename?: 'CaptionType'; text: string } | null;
            border?: {
              __typename?: 'BorderType';
              enabled: boolean;
              color?: string | null;
              gradientColors?: Array<string> | null;
              clearAfterClick?: boolean | null;
            } | null;
            positioning?: {
              __typename?: 'PositioningType';
              position: string;
              bottomLeft?: {
                __typename?: 'BottomLeft';
                marginBottom?: number | null;
                marginLeft?: number | null;
              } | null;
              bottomRight?: {
                __typename?: 'BottomRight';
                marginBottom?: number | null;
                marginRight?: number | null;
              } | null;
            } | null;
            addOns?: {
              __typename?: 'AddOnsType';
              bubble?: {
                __typename?: 'BubbleAddOnType';
                enabled: boolean;
                text: string;
                design: {
                  __typename?: 'BubbleAddOnDesignType';
                  backgroundColor: string;
                  textColor: string;
                };
              } | null;
            } | null;
          };
        };
      } | null;
    } | null;
    stages: Array<{
      __typename?: 'StageType';
      id: string;
      name?: string | null;
      nextStageId?: string | null;
      content: {
        __typename?: 'ContentType';
        id: string;
        type: string;
        videoAssetId: string;
        videoAssetName: string;
        videoAssetUrl: string;
        videoAssetThumbnailUrl?: string | null;
      };
      actions: Array<{
        __typename?: 'ActionType';
        type: string;
        order: number;
        label: string;
        isFinal: boolean;
        closeWidget: boolean;
        redirect?: {
          __typename?: 'RedirectActionType';
          targetUrl: string;
          openNewTab: boolean;
        } | null;
        mailto?: { __typename?: 'MailtoActionType'; email: string } | null;
        emailCollection?: {
          __typename?: 'EmailCollectionActionType';
          topText?: string | null;
          instructionsText: string;
          disclaimerText: string;
          disclaimerUrl?: string | null;
        } | null;
        phoneCollection?: {
          __typename?: 'PhoneCollectionActionType';
          topText?: string | null;
          instructionsText: string;
          disclaimerText: string;
          disclaimerUrl?: string | null;
          validationPattern?: string | null;
        } | null;
      }>;
    }>;
  };
};

export const CreateControllerDocument = gql`
  mutation createController($input: CreateControllerInputType!) {
    createController(input: $input) {
      id
      name
      validFrom
      validUntil
      isActive
      url
      initialStageId
      layout {
        type
        widget {
          theme {
            colors {
              primary
            }
          }
          design {
            minified {
              closable
              widgetSize
              shape
              caption {
                text
              }
              border {
                enabled
                color
                gradientColors
                clearAfterClick
              }
              positioning {
                position
                bottomLeft {
                  marginBottom
                  marginLeft
                }
                bottomRight {
                  marginBottom
                  marginRight
                }
              }
              addOns {
                bubble {
                  enabled
                  text
                  design {
                    backgroundColor
                    textColor
                  }
                }
              }
            }
          }
        }
      }
      stages {
        id
        name
        nextStageId
        content {
          id
          type
          videoAssetId
          videoAssetName
          videoAssetUrl
          videoAssetThumbnailUrl
        }
        actions {
          type
          order
          label
          isFinal
          closeWidget
          redirect {
            targetUrl
            openNewTab
          }
          mailto {
            email
          }
          emailCollection {
            topText
            instructionsText
            disclaimerText
            disclaimerUrl
          }
          phoneCollection {
            topText
            instructionsText
            disclaimerText
            disclaimerUrl
            validationPattern
          }
        }
      }
    }
  }
`;
export type CreateControllerMutationFn = Apollo.MutationFunction<
  CreateControllerMutation,
  CreateControllerMutationVariables
>;

/**
 * __useCreateControllerMutation__
 *
 * To run a mutation, you first call `useCreateControllerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateControllerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createControllerMutation, { data, loading, error }] = useCreateControllerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateControllerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateControllerMutation,
    CreateControllerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateControllerMutation, CreateControllerMutationVariables>(
    CreateControllerDocument,
    options,
  );
}
export type CreateControllerMutationHookResult = ReturnType<typeof useCreateControllerMutation>;
export type CreateControllerMutationResult = Apollo.MutationResult<CreateControllerMutation>;
export type CreateControllerMutationOptions = Apollo.BaseMutationOptions<
  CreateControllerMutation,
  CreateControllerMutationVariables
>;
