import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Grid, Paper, Stack } from '@mui/material';
import { WidgetPreview } from '../../shared/WidgetPreview';
import { ControllerBasicInfo } from './components/ControllerBasicInfo';
import { SchedulingConfig } from './components/SchedulingConfig';
import { ActionConfig } from './components/ActionConfig';
import { DomainConfig } from './components/DomainConfig';
import { useWizard } from '../../context/WizardContext';
import { ConfigureFormValues, configureSchema } from './validation';
import { useControllerCreation } from './hooks/useControllerCreation';
import ErrorSnackbar from '../../../Utils/ErrorSnackbar';
import { ThemeColorConfig } from '../StyleStep/components/ThemeColorConfig';
import { createDefaultEmailAction, createDefaultPhoneAction } from './utils/actionHelpers';
import { ActionType } from '../../../../interfaces';
import { ControllerDraft } from '../../types';

const ConfigureStep: React.FC = () => {
  const { state, dispatch } = useWizard();
  const { createControllerFromDraft } = useControllerCreation();
  const [error, setError] = useState<string | null>(null);

  // Create a dedicated state for tracking the current action type to ensure consistency
  const [currentActionType, setCurrentActionType] = useState<'emailCollection' | 'phoneCollection'>(
    state.draft?.stages[0]?.actions[0]?.type === 'phoneCollection'
      ? 'phoneCollection'
      : 'emailCollection',
  );

  // This will be used in the preview AND for API submission
  const previewState = {
    draftController: state.draft!,
    controllerId: state.controllerId!,
    previewKey: state.previewKey,
    isSaving: state.isSaving,
  };

  // This function should handle ALL draft updates related to form changes
  const updateDraftFromForm = (
    formData: Partial<ConfigureFormValues>,
    actionTypeChanged = false,
  ) => {
    if (!state.draft) return;

    // Get the current action from the draft
    const existingAction = state.draft.stages[0].actions[0];

    // Get the action type - use the new one if changing, or existing one if not
    const actionType = formData.actionType || existingAction.type;

    // Check if action type is changing
    const isActionTypeChanging = actionTypeChanged && formData.actionType !== existingAction.type;

    // Preserve important values when changing action types
    const existingDisclaimerUrl =
      existingAction.emailCollection?.disclaimerUrl ||
      existingAction.phoneCollection?.disclaimerUrl ||
      'https://www.closer2.io/privacy-policy#Terms';

    // Build the updated action
    const updatedAction = { ...existingAction };

    // Update basic properties if provided in form data
    if (formData.action?.buttonLabel) {
      updatedAction.label = formData.action.buttonLabel;
    }

    // If action type is changing or specific action fields are being updated
    if (isActionTypeChanging || formData.action) {
      // Handle action type specific properties
      if (actionType === 'emailCollection') {
        updatedAction.type = 'emailCollection';
        updatedAction.emailCollection = {
          ...(existingAction.emailCollection || {}),
          instructionsText:
            formData.action?.instructionsText ||
            existingAction.emailCollection?.instructionsText ||
            'Please leave your email to get a discount',
          disclaimerText:
            formData.action?.disclaimerText ||
            existingAction.emailCollection?.disclaimerText ||
            'In order to continue accept Terms and Conditions',
          disclaimerUrl: existingDisclaimerUrl,
        };
        // Set phoneCollection to null for API compatibility
        updatedAction.phoneCollection = null;
      } else {
        updatedAction.type = 'phoneCollection';
        updatedAction.phoneCollection = {
          ...(existingAction.phoneCollection || {}),
          instructionsText:
            formData.action?.instructionsText ||
            existingAction.phoneCollection?.instructionsText ||
            'Please leave us your phone to get a discount',
          disclaimerText:
            formData.action?.disclaimerText ||
            existingAction.phoneCollection?.disclaimerText ||
            'In order to continue accept Terms and Conditions',
          validationPattern:
            formData.action?.validationPattern ||
            existingAction.phoneCollection?.validationPattern ||
            '',
          disclaimerUrl: existingDisclaimerUrl,
        };
        // Set emailCollection to null for API compatibility
        updatedAction.emailCollection = null;
      }
    }

    // Create the updated draft object
    const updatedDraft = {
      ...state.draft,
      // Update name if provided
      ...(formData.name ? { name: formData.name } : {}),
      // Update scheduling if provided
      ...(formData.scheduling
        ? {
            validFrom: formData.scheduling.isAllTime ? null : formData.scheduling.validFrom,
            validUntil: formData.scheduling.isAllTime ? null : formData.scheduling.validUntil,
          }
        : {}),
      // Update theme if provided
      ...(formData.theme
        ? {
            layout: {
              ...state.draft.layout,
              widget: {
                ...state.draft.layout.widget,
                theme: {
                  ...state.draft.layout.widget.theme,
                  colors: {
                    ...state.draft.layout.widget.theme?.colors,
                    primary: formData.theme.colors.primary,
                  },
                },
              },
            },
          }
        : {}),
      // Always update the action
      stages: state.draft.stages.map((stage) => ({
        ...stage,
        actions: [updatedAction],
      })),
    };

    console.log(
      `Updating draft`,
      JSON.stringify({
        actionTypeChanged,
        actionType,
        updatedDraft,
      }),
    );

    // Update local state if action type changed
    if (actionTypeChanged && formData.actionType) {
      setCurrentActionType(formData.actionType);
    }

    // Update the draft in context
    dispatch({
      type: 'SET_DRAFT',
      payload: updatedDraft,
    });
  };

  // Modified to use the unified update function
  const handleActionTypeChange = (actionType: 'emailCollection' | 'phoneCollection') => {
    if (!state.draft || actionType === currentActionType) return;

    // Use the updateDraftFromForm function with action type change
    updateDraftFromForm({ actionType }, true);
  };

  // Handle form field changes with proper typing
  const handleFormChange = (field: string, value: any) => {
    // Build a properly typed partial form data object
    const formData: Partial<ConfigureFormValues> = {};

    if (field === 'buttonLabel') {
      formData.action = {
        buttonLabel: value,
        instructionsText: watch('action.instructionsText') || '',
        disclaimerText: watch('action.disclaimerText') || '',
        validationPattern: watch('action.validationPattern'),
      };
    } else if (field === 'instructionsText') {
      formData.action = {
        buttonLabel: watch('action.buttonLabel') || '',
        instructionsText: value,
        disclaimerText: watch('action.disclaimerText') || '',
        validationPattern: watch('action.validationPattern'),
      };
    } else if (field === 'disclaimerText') {
      formData.action = {
        buttonLabel: watch('action.buttonLabel') || '',
        instructionsText: watch('action.instructionsText') || '',
        disclaimerText: value,
        validationPattern: watch('action.validationPattern'),
      };
    } else if (field === 'validationPattern') {
      formData.action = {
        buttonLabel: watch('action.buttonLabel') || '',
        instructionsText: watch('action.instructionsText') || '',
        disclaimerText: watch('action.disclaimerText') || '',
        validationPattern: value,
      };
    } else if (field === 'name') {
      formData.name = value;
    }

    // Update the draft with these changes
    updateDraftFromForm(formData);
  };

  // Create controller with proper handling of latest state
  const createController = async (data: ConfigureFormValues) => {
    try {
      if (!state.draft) {
        throw new Error('Draft not found');
      }

      // First update the draft one last time with the form data
      updateDraftFromForm(data);

      // Instead of trying to access state immediately (which might not have updated yet),
      // or using setTimeout (which is a hacky way to get the latest state),
      // let's explicitly create a controller draft based on our form data
      // and the current draft state

      // Start with a deep copy of the latest known draft
      const controllerDraft: ControllerDraft = JSON.parse(JSON.stringify(state.draft));

      // Update with form data
      controllerDraft.name = data.name || controllerDraft.name || '';
      controllerDraft.validFrom = data.scheduling.isAllTime ? null : data.scheduling.validFrom;
      controllerDraft.validUntil = data.scheduling.isAllTime ? null : data.scheduling.validUntil;

      // Most importantly, ensure the action type and collection match the form
      if (
        controllerDraft.stages &&
        controllerDraft.stages.length > 0 &&
        controllerDraft.stages[0].actions &&
        controllerDraft.stages[0].actions.length > 0
      ) {
        const action = controllerDraft.stages[0].actions[0];

        // Always set the action type to match the form data
        action.type = data.actionType;
        action.label = data.action.buttonLabel;

        // Get any existing disclaimerUrl
        const existingDisclaimerUrl =
          action.emailCollection?.disclaimerUrl ||
          action.phoneCollection?.disclaimerUrl ||
          'https://www.closer2.io/privacy-policy#Terms';

        // Configure the correct collection type based on the form action type
        if (data.actionType === 'emailCollection') {
          action.emailCollection = {
            instructionsText: data.action.instructionsText,
            disclaimerText: data.action.disclaimerText,
            disclaimerUrl: existingDisclaimerUrl,
          };
          action.phoneCollection = null;
        } else {
          action.phoneCollection = {
            instructionsText: data.action.instructionsText,
            disclaimerText: data.action.disclaimerText,
            validationPattern: data.action.validationPattern || '',
            disclaimerUrl: existingDisclaimerUrl,
          };
          action.emailCollection = null;
        }
      }

      // console.log('Submitting controller with explicit form data:', controllerDraft);

      // Submit this explicitly constructed draft that we know has the right values
      await createControllerFromDraft(controllerDraft);

      // If successful, move to next step
      dispatch({ type: 'SET_STEP', payload: state.currentStep + 1 });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create controller');
    }
  };

  // Form setup with react-hook-form
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
    setValue,
    watch,
    trigger,
  } = useForm<ConfigureFormValues>({
    resolver: yupResolver(configureSchema),
    defaultValues: {
      name: state.draft?.name || '',
      actionType: currentActionType,
      action: {
        buttonLabel: state.draft?.stages[0]?.actions[0]?.label || '',
        instructionsText:
          currentActionType === 'emailCollection'
            ? state.draft?.stages[0]?.actions[0]?.emailCollection?.instructionsText || ''
            : state.draft?.stages[0]?.actions[0]?.phoneCollection?.instructionsText || '',
        disclaimerText:
          currentActionType === 'emailCollection'
            ? state.draft?.stages[0]?.actions[0]?.emailCollection?.disclaimerText || ''
            : state.draft?.stages[0]?.actions[0]?.phoneCollection?.disclaimerText || '',
        validationPattern:
          state.draft?.stages[0]?.actions[0]?.phoneCollection?.validationPattern || '',
      },
      scheduling: {
        isAllTime: state.draft?.validFrom === null && state.draft?.validUntil === null,
        validFrom: state.draft?.validFrom || null,
        validUntil: state.draft?.validUntil || null,
      },
      theme: {
        colors: {
          primary: state.draft?.layout.widget?.theme?.colors?.primary || '#6366f1',
        },
      },
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  // Watch for action type changes
  const selectedActionType = watch('actionType');

  // React to action type changes from the form
  useEffect(() => {
    if (selectedActionType && selectedActionType !== currentActionType) {
      handleActionTypeChange(selectedActionType);
    }
  }, [selectedActionType]);

  // Sync the form when the draft changes
  useEffect(() => {
    // If the draft changes and has a different action type than what we're tracking,
    // update our tracked action type
    const draftActionType = state.draft?.stages[0]?.actions[0]?.type;
    if (draftActionType && draftActionType !== currentActionType) {
      setCurrentActionType(draftActionType as 'emailCollection' | 'phoneCollection');

      // Also update the form
      setValue('actionType', draftActionType as 'emailCollection' | 'phoneCollection');
    }
  }, [state.draft, setValue]);

  // Validate form on mount and when draft changes
  useEffect(() => {
    trigger();
  }, [trigger, state.draft]);

  // Set up form handlers for the wizard
  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        configure: {
          handleSubmit: handleSubmit(createController),
          isSubmitting,
          isValid,
        },
      },
    });

    return () => {
      dispatch({
        type: 'SET_FORM_HANDLERS',
        payload: {
          configure: undefined,
        },
      });
    };
  }, [isSubmitting, isValid, handleSubmit, dispatch]);

  // Update the draft when fields change
  const handleTextChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    // Update the form value
    if (field === 'buttonLabel') {
      setValue('action.buttonLabel', value, { shouldValidate: true });
    } else if (field === 'instructionsText') {
      setValue('action.instructionsText', value, { shouldValidate: true });
    } else if (field === 'disclaimerText') {
      setValue('action.disclaimerText', value, { shouldValidate: true });
    } else if (field === 'validationPattern') {
      setValue('action.validationPattern', value, { shouldValidate: true });
    }

    // Update the draft
    handleFormChange(field, value);
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* Form Column */}
        <Grid item md={6} xs={12}>
          <Stack spacing={3}>
            {/* Basic Info */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <ControllerBasicInfo errors={errors} register={register} />
            </Paper>

            {/* Theme Color */}
            {/* <Paper elevation={3} sx={{ p: 3 }}>
              <ThemeColorConfig setValue={setValue} />
            </Paper> */}

            {/* Scheduling */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <SchedulingConfig
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
              />
            </Paper>

            {/* Action Settings */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <ActionConfig
                errors={errors}
                register={register}
                setValue={setValue}
                trigger={trigger}
                watch={watch}
              />
            </Paper>

            {/* Domain Settings */}
            <Paper elevation={3} sx={{ p: 3 }}>
              <DomainConfig />
            </Paper>
          </Stack>
        </Grid>

        {/* Preview Column */}
        <Grid item md={6} xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              position: 'sticky',
              top: 24,
              height: 'calc(100vh - 200px)',
              overflow: 'hidden',
            }}
          >
            <WidgetPreview fullscreenMode previewState={previewState} />
          </Paper>
        </Grid>
      </Grid>
      <ErrorSnackbar message={error || ''} onClose={() => setError(null)} open={!!error} />
    </>
  );
};

export default ConfigureStep;
