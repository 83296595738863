// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/validation.ts
import * as yup from 'yup';

export const configureSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be at most 50 characters')
    .test('valid-text', 'Can only contain letters, numbers, and basic punctuation', (value) => {
      if (!value) return true;
      return /^[\p{L}\p{M}\p{N}\p{Sc}\p{P}\p{S}\p{Z}]+$/u.test(value);
    })
    .test('no-html', 'Cannot contain HTML tags', (value) => {
      if (!value) return true;
      return !/<[^>]*>/g.test(value);
    })
    .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
      if (!value) return true;
      return value.trim().length > 0;
    }),

  actionType: yup
    .string()
    .oneOf(['emailCollection', 'phoneCollection'])
    .required('Action type is required'),

  action: yup
    .object({
      buttonLabel: yup
        .string()
        .required('Button label is required')
        .min(3, 'Button label must be at least 3 characters')
        .max(50, 'Button label must be at most 50 characters')
        .test('valid-text', 'Can only contain letters, numbers, and basic punctuation', (value) => {
          if (!value) return true;
          return /^[\p{L}\p{M}\p{N}\p{Sc}\p{P}\p{S}\p{Z}]+$/u.test(value);
        })
        .test('no-html', 'Cannot contain HTML tags', (value) => {
          if (!value) return true;
          return !/<[^>]*>/g.test(value);
        })
        .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }),

      instructionsText: yup
        .string()
        .required('Instructions text is required')
        .min(3, 'Instructions text must be at least 3 characters')
        .max(200, 'Instructions text must be at most 200 characters')
        .test('valid-text', 'Can only contain letters, numbers, and basic punctuation', (value) => {
          if (!value) return true;
          return /^[\p{L}\p{M}\p{N}\p{Sc}\p{P}\p{S}\p{Z}]+$/u.test(value);
        })
        .test('no-html', 'Cannot contain HTML tags', (value) => {
          if (!value) return true;
          return !/<[^>]*>/g.test(value);
        })
        .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }),

      disclaimerText: yup
        .string()
        .required('Disclaimer text is required')
        .min(3, 'Disclaimer text must be at least 3 characters')
        .max(200, 'Disclaimer text must be at most 200 characters')
        .test('valid-text', 'Can only contain letters, numbers, and basic punctuation', (value) => {
          if (!value) return true;
          return /^[\p{L}\p{M}\p{N}\p{Sc}\p{P}\p{S}\p{Z}]+$/u.test(value);
        })
        .test('no-html', 'Cannot contain HTML tags', (value) => {
          if (!value) return true;
          return !/<[^>]*>/g.test(value);
        })
        .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
          if (!value) return true;
          return value.trim().length > 0;
        }),

      disclaimerUrl: yup.string().url('Must be a valid URL').nullable(),

      validationPattern: yup.string().optional(),
      // .when('$actionType', {
      //   is: 'phoneCollection',
      //   then: yup.string().nullable(),
      //   otherwise: yup.string().nullable().strip(),
      // }),
    })
    .required(),

  scheduling: yup
    .object({
      isAllTime: yup.boolean().required().defined(),
      validFrom: yup.string().nullable().default(null),
      validUntil: yup
        .string()
        .nullable()
        .default(null)
        .test('valid-end-date', 'End date must be after start date', function (value) {
          const { isAllTime, validFrom } = this.parent;
          if (isAllTime) return true;
          if (!validFrom || !value) return true;
          return new Date(value) > new Date(validFrom);
        }),
    })
    .required(),

  theme: yup.object({
    colors: yup.object({
      primary: yup.string().required('Theme color is required'),
    }),
  }),
});

export interface ConfigureFormValues {
  name: string;
  actionType: 'emailCollection' | 'phoneCollection';
  action: {
    buttonLabel: string;
    instructionsText: string;
    disclaimerText: string;
    disclaimerUrl?: string | null;
    validationPattern?: string;
  };
  scheduling: {
    isAllTime: boolean;
    validFrom: string | null;
    validUntil: string | null;
  };
  theme: {
    colors: {
      primary: string;
    };
  };
}
