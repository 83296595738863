import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Switch, Paper, Divider } from '@mui/material';
import { TestIds } from '../../test-utils';

interface CrmBridgeIntegrationProps {
  isEnabled?: boolean;
  apiKey?: string;
  userId: string; // End user identifier
  onConnectionComplete: (crmBridgeKey: string) => void;
  crmBridgeKey?: string; // Added prop for existing bridge key
  apiBaseUrl?: string; // Optional API base URL
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'crm-connection-setup': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          'api-key'?: string;
          'end-user-identifier'?: string;
          'crm-bridge-key'?: string; // Added attribute for existing bridge key
          'api-base-url'?: string;
        },
        HTMLElement
      >;
    }
  }
}

const CRM_WIDGET_ID = 'crm-bridge-widget';

const CrmBridgeIntegration: React.FC<CrmBridgeIntegrationProps> = ({
  isEnabled = false,
  apiKey = '',
  userId,
  onConnectionComplete,
  crmBridgeKey = '',
  apiBaseUrl,
}) => {
  const crmWidgetRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const currentElement = crmWidgetRef.current;

    if (!currentElement) {
      // Explicitly return nothing to satisfy ESLint
      return undefined;
    }

    const handleComplete = (event: Event) => {
      const customEvent = event as CustomEvent<string>;
      const bridgeKey = customEvent.detail;
      console.log('CRM Bridge event received:', bridgeKey);
      onConnectionComplete(bridgeKey);
    };

    currentElement.addEventListener('crm-connection-complete', handleComplete);

    // Explicitly return the cleanup function
    return () => {
      currentElement.removeEventListener('crm-connection-complete', handleComplete);
    };
  }, [onConnectionComplete]);

  return (
    <Box data-testid={TestIds.SETTINGS.CRM_BRIDGE}>
      <Typography gutterBottom variant="h6">
        CRM Bridge Integration
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 3 }} variant="body2">
        Connect your widget with third-party CRM systems
      </Typography>

      <Box my={3}>
        <crm-connection-setup
          id={CRM_WIDGET_ID}
          ref={crmWidgetRef} // <- Attach ref here
          api-key={apiKey}
          end-user-identifier={userId}
          crm-bridge-key={crmBridgeKey}
          api-base-url={apiBaseUrl}
          style={{
            display: 'block',
            width: '100%',
            minHeight: '300px',
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
            padding: '16px',
          }}
        />

        {/* alternative to pass callback */}
        {/* <crm-connection-setup
          api-key={apiKey}
          end-user-identifier={userId}
          crm-bridge-key={crmBridgeKey}
          completeCallback={(bridgeKey: string) => {
            onConnectionComplete(bridgeKey);
            return Promise.resolve(true);
          }}
        /> */}
      </Box>
    </Box>
  );
};

export default React.memo(CrmBridgeIntegration);
