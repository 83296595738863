// src/components/CreateNewControllerWizardV2/steps/ConfigureStep/utils/actionHelpers.ts
/**
 * Creates a default email collection action
 */
export const createDefaultEmailAction = (label = 'Leave your email') => {
  return {
    type: 'emailCollection',
    isFinal: true,
    closeWidget: true,
    order: 0,
    label,
    emailCollection: {
      instructionsText: 'Please leave your email to get a discount',
      disclaimerText: 'In order to continue accept Terms and Conditions',
      disclaimerUrl: 'https://www.closer2.io/privacy-policy#Terms',
    },
  };
};

/**
 * Creates a default phone collection action
 */
export const createDefaultPhoneAction = (label = 'Leave us your phone number') => {
  return {
    type: 'phoneCollection',
    isFinal: true,
    closeWidget: true,
    order: 0,
    label,
    phoneCollection: {
      instructionsText: 'Please leave us your phone number to get a callback',
      disclaimerText: 'In order to continue accept Terms and Conditions',
      disclaimerUrl: 'https://www.closer2.io/privacy-policy#Terms',
      validationPattern: '',
    },
  };
};
