// src/components/CreateNewControllerWizardV2/steps/VideoUploadStep/validation.ts

import * as yup from 'yup';

export const videoUploadSchema = yup.object().shape({
  name: yup
    .string()
    .required('Video name is required')
    .min(3, 'Video name must be at least 3 characters')
    .max(50, 'Video name cannot be longer than 50 characters')
    .test('valid-text', 'Can only contain letters, numbers, and basic punctuation', (value) => {
      if (!value) return true;
      return /^[\p{L}\p{M}\p{N}\p{Sc}\p{P}\p{S}\p{Z}]+$/u.test(value);
    })
    .test('no-html', 'Cannot contain HTML tags', (value) => {
      if (!value) return true;
      return !/<[^>]*>/g.test(value);
    })
    .test('no-empty-spaces', 'Cannot contain only spaces', (value) => {
      if (!value) return true;
      return value.trim().length > 0;
    }),
  videoAsset: yup.object().shape({
    fileId: yup.string().required('Video file is required'),
    name: yup.string().required('Video name is required'),
  }),
});
