// src/components/CreateNewControllerWizardV2/context/WizardContext.tsx

import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { ControllerDraft, WizardAction, WizardState } from '../types';

const defaultBorder = {
  enabled: true,
  gradientColors: ['#00df81', '#653ffe'] as [string, string],
};

const defaultBubble = {
  enabled: true,
  text: 'get a discount!',
  design: {
    backgroundColor: '#653ffe',
    textColor: '#ffffff',
  },
};

export const defaultDraft: ControllerDraft = {
  name: '',
  isActive: true,
  initialStageId: '0',
  validFrom: null,
  validUntil: null,
  stages: [
    {
      id: '0',
      name: 'Initial Stage',
      content: {
        id: '',
        type: 'video',
        videoAssetId: '',
        videoAssetName: '',
        videoAssetUrl: '',
      },
      actions: [
        {
          type: 'emailCollection',
          isFinal: true,
          closeWidget: true,
          order: 0,
          label: 'Leave your email',
          emailCollection: {
            instructionsText: 'Please leave your email to get a discount',
            disclaimerText: 'In order to continue accept Terms and Conditions',
            disclaimerUrl: 'https://www.closer2.io/privacy-policy#Terms',
          },
        },
      ],
    },
  ],
  layout: {
    type: 'widget',
    widget: {
      theme: {
        colors: {
          primary: '#6366f1',
        },
      },
      design: {
        minified: {
          widgetSize: 'M',
          shape: 'circle',
          border: defaultBorder,
          positioning: {
            position: 'bottomLeft',
            bottomLeft: {
              marginBottom: 20,
              marginLeft: 20,
            },
            bottomRight: {
              marginBottom: 20,
              marginRight: 20,
            },
          },
          addOns: {
            bubble: defaultBubble,
          },
        },
      },
    },
  },
};

const initialState: WizardState = {
  currentStep: 0,
  videoData: null,
  styleConfig: {
    widgetSize: 'M',
    shape: 'circle',
    border: defaultBorder,
    positioning: {
      position: 'bottomLeft',
      bottomLeft: {
        marginBottom: 20,
        marginLeft: 20,
      },
      bottomRight: {
        marginBottom: 20,
        marginRight: 20,
      },
    },
    addOns: {
      bubble: defaultBubble,
    },
  },
  actionConfig: {
    name: '',
    isAllTime: true,
    action: {
      buttonLabel: 'Leave your email',
      instructionsText: 'Please leave your email to get a discount',
      disclaimerText: 'In order to continue accept Terms and Conditions',
    },
  },
  configurationConfig: {
    name: 'New Controller',
    isAllTime: true,
    actionType: 'emailCollection', // Add actionType field with default value
    action: {
      buttonLabel: 'Leave your email',
      instructionsText: 'Please leave your email to get a discount',
      disclaimerText: 'In order to continue accept Terms and Conditions',
    },
  },
  controllerId: null,
  error: null,
  isSaving: false,
  previewKey: 0,
  activeTab: 0,
  forms: {},
  draft: { ...defaultDraft },
  originalController: undefined,
};

const wizardReducer = (state: WizardState, action: WizardAction): WizardState => {
  switch (action.type) {
    case 'SET_DRAFT': {
      // Ensure we're not losing any nested properties
      const newDraft = action.payload;

      // Make sure every action has the correct structure with disclaimerUrl
      const updatedDraft = {
        ...newDraft,
        stages: newDraft.stages.map((stage) => ({
          ...stage,
          actions: stage.actions.map((actionItem) => {
            // Ensure email collection has disclaimerUrl
            if (actionItem.type === 'emailCollection') {
              return {
                ...actionItem,
                // Make sure emailCollection is never null, but at least an empty object
                emailCollection: {
                  instructionsText: actionItem.emailCollection?.instructionsText || '',
                  disclaimerText: actionItem.emailCollection?.disclaimerText || '',
                  disclaimerUrl:
                    actionItem.emailCollection?.disclaimerUrl ||
                    'https://www.closer2.io/privacy-policy#Terms',
                },
                // Set phoneCollection to undefined to prevent both being sent
                phoneCollection: undefined,
              };
            }
            // Ensure phone collection has disclaimerUrl
            if (actionItem.type === 'phoneCollection') {
              return {
                ...actionItem,
                // Make sure phoneCollection is never null, but at least an empty object
                phoneCollection: {
                  instructionsText: actionItem.phoneCollection?.instructionsText || '',
                  disclaimerText: actionItem.phoneCollection?.disclaimerText || '',
                  disclaimerUrl:
                    actionItem.phoneCollection?.disclaimerUrl ||
                    'https://www.closer2.io/privacy-policy#Terms',
                  validationPattern: actionItem.phoneCollection?.validationPattern || '',
                },
                // Set emailCollection to undefined to prevent both being sent
                emailCollection: undefined,
              };
            }
            return actionItem;
          }),
        })),
      };

      return {
        ...state,
        draft: updatedDraft,
        previewKey: state.previewKey + 1,
      };
    }

    case 'UPDATE_DRAFT': {
      const updatedDraft = state.draft
        ? {
            ...state.draft,
            layout: {
              ...state.draft.layout,
              widget: {
                ...state.draft.layout.widget,
                design: {
                  ...state.draft.layout.widget.design,
                  minified: {
                    ...state.draft.layout.widget.design.minified,
                    ...action.payload.layout?.widget?.design?.minified,
                  },
                },
              },
            },
          }
        : { ...defaultDraft, ...action.payload };

      return {
        ...state,
        draft: updatedDraft,
        controllerId: updatedDraft.id || state.controllerId,
        styleConfig: {
          ...state.styleConfig,
          ...updatedDraft.layout.widget.design.minified,
        },
        previewKey: state.previewKey + 1,
      };
    }

    case 'SET_ORIGINAL_CONTROLLER':
      return {
        ...state,
        originalController: action.payload,
        draft: { ...action.payload },
        // Sync with legacy state
        controllerId: action.payload.id || null,
        styleConfig: {
          ...state.styleConfig,
          ...action.payload.layout.widget.design.minified,
        },
      };

    case 'RESET_DRAFT':
      return {
        ...state,
        draft: state.originalController ? { ...state.originalController } : { ...defaultDraft },
        previewKey: state.previewKey + 1,
      };

    case 'SET_STEP':
      return { ...state, currentStep: action.payload };

    case 'SET_VIDEO':
      return { ...state, videoData: action.payload };
    case 'UPDATE_STYLE': {
      const updatedStyle = { ...state.styleConfig, ...action.payload };
      return {
        ...state,
        styleConfig: updatedStyle,
        // Sync with draft
        draft: state.draft
          ? {
              ...state.draft,
              layout: {
                ...state.draft.layout,
                widget: {
                  ...state.draft.layout.widget,
                  design: {
                    ...state.draft.layout.widget.design,
                    minified: {
                      ...state.draft.layout.widget.design.minified,
                      ...updatedStyle,
                    },
                  },
                },
              },
            }
          : state.draft,
        previewKey: state.previewKey + 1,
      };
    }
    case 'UPDATE_ACTION':
      return {
        ...state,
        actionConfig: { ...state.actionConfig, ...action.payload },
        previewKey: state.previewKey + 1,
      };
    case 'SET_CONTROLLER_ID':
      return { ...state, controllerId: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_SAVING':
      return { ...state, isSaving: action.payload };
    case 'TRIGGER_PREVIEW_REFRESH':
      return { ...state, previewKey: state.previewKey + 1 };
    case 'SET_FORM_HANDLERS':
      return {
        ...state,
        forms: {
          ...state.forms,
          ...action.payload,
        },
      };
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'UPDATE_CONFIGURATION': {
      // Update configuration config state
      const newConfig = {
        ...state.configurationConfig,
        ...action.payload,
        action: {
          ...state.configurationConfig.action,
          ...(action.payload.action || {}),
        },
      };

      // Also update draft if it exists and actionType is changed
      let updatedDraft = state.draft;
      if (
        updatedDraft &&
        action.payload.actionType &&
        action.payload.actionType !== state.configurationConfig.actionType
      ) {
        const { actionType } = action.payload;

        updatedDraft = {
          ...updatedDraft,
          stages: updatedDraft.stages.map((stage) => ({
            ...stage,
            actions: stage.actions.map((innerAction) => {
              // Basic action updates
              const updatedAction = {
                ...innerAction,
                type: actionType,
              };

              // Get the disclaimerUrl from existing collection or default URL
              const existingDisclaimerUrl =
                innerAction.emailCollection?.disclaimerUrl ||
                innerAction.phoneCollection?.disclaimerUrl ||
                'https://www.closer2.io/privacy-policy#Terms';

              // Add appropriate collection type based on actionType
              if (actionType === 'emailCollection') {
                return {
                  ...updatedAction,
                  emailCollection: {
                    instructionsText:
                      newConfig.action.instructionsText ||
                      innerAction.emailCollection?.instructionsText ||
                      '',
                    disclaimerText:
                      newConfig.action.disclaimerText ||
                      innerAction.emailCollection?.disclaimerText ||
                      '',
                    disclaimerUrl: existingDisclaimerUrl,
                  },
                  phoneCollection: undefined,
                };
              }
              // phoneCollection
              return {
                ...updatedAction,
                phoneCollection: {
                  instructionsText: newConfig.action.instructionsText || '',
                  disclaimerText: newConfig.action.disclaimerText || '',
                  disclaimerUrl: existingDisclaimerUrl,
                  validationPattern: newConfig.action.validationPattern || '',
                },
                emailCollection: undefined,
              };
            }),
          })),
        };
      }

      return {
        ...state,
        configurationConfig: newConfig,
        draft: updatedDraft || state.draft,
        previewKey: state.previewKey + 1,
      };
    }
    default:
      return state;
  }
};

const WizardContext = createContext<{
  state: WizardState;
  dispatch: React.Dispatch<WizardAction>;
} | null>(null);

export const WizardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(wizardReducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state]);

  return <WizardContext.Provider value={contextValue}>{children}</WizardContext.Provider>;
};

export const useWizard = () => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider');
  }
  return context;
};
