import React, { memo, useCallback } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { LoadingSpinner } from '../Utils';
import { TestIds } from '../../test-utils';
import { useTrustedDomains } from '../../hooks/useTrustedDomains';
import TrustedDomainsManager from '../Common/TrustedDomainsManager/TrustedDomainsManager';
import CrmBridgeIntegration from './CrmBridgeIntegration';
import { useCrmBridge } from '../../hooks/useCrmBridge';
import { appConfig } from '../../app-config';
import { useExternalScript } from '../../hooks/useExternalScript';

const SettingsConfig = memo(() => {
  const { domains, domainLimit, isLoading, handleAddDomain, handleRemoveDomain } =
    useTrustedDomains();
  const {
    config,
    isLoading: isCrmLoading,
    userId,
    handleConnectionComplete,
    isConnected,
    currentKey,
  } = useCrmBridge();

  // Lazily load the CRM bridge widget scripts using the modern module/nomodule pattern
  const scriptStatus = useExternalScript({
    esmSrc: appConfig.crmBridge.widgetScriptEsm,
    legacySrc: appConfig.crmBridge.widgetScriptLegacy,
  });

  // Check if scripts are still loading
  const scriptsLoading = scriptStatus === 'loading' || scriptStatus === 'idle';

  // Create a stable callback function with useCallback
  const onConnectionCompleteHandler = useCallback(
    (crmBridgeKey: string) => {
      console.log('SettingsConfig: Connection complete with key:', crmBridgeKey);
      handleConnectionComplete(crmBridgeKey);
    },
    [handleConnectionComplete],
  );

  if (isLoading && isCrmLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box data-testid={TestIds.SETTINGS.CONTAINER}>
      <Typography
        sx={{
          fontSize: { xs: 28, md: 36 },
          fontWeight: 700,
          pb: 3,
          pt: { xs: 3, md: 5.5 },
        }}
        variant="h4"
      >
        Settings
      </Typography>

      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, md: 4 },
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box mb={4}>
          <Typography gutterBottom variant="h6">
            Domain Management
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3 }} variant="body2">
            Configure which domains can host your widget
          </Typography>

          <TrustedDomainsManager
            description="Configure which domains can host your widget"
            domainLimit={domainLimit}
            domains={domains}
            isLoading={isLoading}
            onAddDomain={handleAddDomain}
            onRemoveDomain={handleRemoveDomain}
            title="Domain Management"
          />
        </Box>

        <Box>
          {appConfig.featureFlags.crmBridgeIntegration &&
            (scriptsLoading ? (
              <LoadingSpinner />
            ) : (
              <CrmBridgeIntegration
                apiBaseUrl={appConfig.crmBridge.apiUrl}
                apiKey={config.apiKey}
                crmBridgeKey={currentKey}
                isEnabled={isConnected}
                onConnectionComplete={onConnectionCompleteHandler}
                userId={userId}
              />
            ))}
        </Box>
      </Paper>
    </Box>
  );
});

export default SettingsConfig;
