import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type GetControllerByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type GetControllerByIdQuery = {
  __typename?: 'Query';
  controller: {
    __typename?: 'ControllerType';
    id: string;
    isActive: boolean;
    name: string;
    url?: string | null;
    validFrom?: string | null;
    validUntil?: string | null;
    initialStageId: string;
    layout?: {
      __typename?: 'LayoutType';
      type: string;
      widget?: {
        __typename?: 'WidgetType';
        theme?: {
          __typename?: 'ThemeType';
          colors?: { __typename?: 'ThemeTypeColors'; primary?: string | null } | null;
        } | null;
        design: {
          __typename?: 'DesignType';
          minified: {
            __typename?: 'MinifiedType';
            closable?: boolean | null;
            widgetSize?: string | null;
            shape?: string | null;
            caption?: { __typename?: 'CaptionType'; text: string } | null;
            border?: {
              __typename?: 'BorderType';
              enabled: boolean;
              color?: string | null;
              gradientColors?: Array<string> | null;
              clearAfterClick?: boolean | null;
            } | null;
            positioning?: {
              __typename?: 'PositioningType';
              position: string;
              bottomLeft?: {
                __typename?: 'BottomLeft';
                marginBottom?: number | null;
                marginLeft?: number | null;
              } | null;
              bottomRight?: {
                __typename?: 'BottomRight';
                marginBottom?: number | null;
                marginRight?: number | null;
              } | null;
            } | null;
            addOns?: {
              __typename?: 'AddOnsType';
              bubble?: {
                __typename?: 'BubbleAddOnType';
                enabled: boolean;
                text: string;
                design: {
                  __typename?: 'BubbleAddOnDesignType';
                  backgroundColor: string;
                  textColor: string;
                };
              } | null;
            } | null;
          };
        };
      } | null;
    } | null;
    stages: Array<{
      __typename?: 'StageType';
      id: string;
      name?: string | null;
      nextStageId?: string | null;
      content: {
        __typename?: 'ContentType';
        id: string;
        type: string;
        videoAssetId: string;
        videoAssetName: string;
        videoAssetUrl: string;
        videoAssetThumbnailUrl?: string | null;
      };
      actions: Array<{
        __typename?: 'ActionType';
        type: string;
        order: number;
        label: string;
        isFinal: boolean;
        closeWidget: boolean;
        redirect?: {
          __typename?: 'RedirectActionType';
          targetUrl: string;
          openNewTab: boolean;
        } | null;
        mailto?: { __typename?: 'MailtoActionType'; email: string } | null;
        emailCollection?: {
          __typename?: 'EmailCollectionActionType';
          topText?: string | null;
          instructionsText: string;
          disclaimerText: string;
          disclaimerUrl?: string | null;
        } | null;
        phoneCollection?: {
          __typename?: 'PhoneCollectionActionType';
          topText?: string | null;
          instructionsText: string;
          disclaimerText: string;
          disclaimerUrl?: string | null;
          validationPattern?: string | null;
        } | null;
      }>;
    }>;
  };
};

export const GetControllerByIdDocument = gql`
  query GetControllerById($id: String!) {
    controller(id: $id) {
      id
      isActive
      name
      url
      validFrom
      validUntil
      initialStageId
      layout {
        type
        widget {
          theme {
            colors {
              primary
            }
          }
          design {
            minified {
              closable
              widgetSize
              shape
              caption {
                text
              }
              border {
                enabled
                color
                gradientColors
                clearAfterClick
              }
              positioning {
                position
                bottomLeft {
                  marginBottom
                  marginLeft
                }
                bottomRight {
                  marginBottom
                  marginRight
                }
              }
              addOns {
                bubble {
                  enabled
                  text
                  design {
                    backgroundColor
                    textColor
                  }
                }
              }
            }
          }
          theme {
            colors {
              primary
            }
          }
        }
      }
      stages {
        id
        name
        nextStageId
        content {
          id
          type
          videoAssetId
          videoAssetName
          videoAssetUrl
          videoAssetThumbnailUrl
        }
        actions {
          type
          order
          label
          isFinal
          closeWidget
          redirect {
            targetUrl
            openNewTab
          }
          mailto {
            email
          }
          emailCollection {
            topText
            instructionsText
            disclaimerText
            disclaimerUrl
          }
          phoneCollection {
            topText
            instructionsText
            disclaimerText
            disclaimerUrl
            validationPattern
          }
        }
      }
    }
  }
`;

/**
 * __useGetControllerByIdQuery__
 *
 * To run a query within a React component, call `useGetControllerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControllerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControllerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetControllerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetControllerByIdQuery, GetControllerByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetControllerByIdQuery, GetControllerByIdQueryVariables>(
    GetControllerByIdDocument,
    options,
  );
}
export function useGetControllerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetControllerByIdQuery,
    GetControllerByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetControllerByIdQuery, GetControllerByIdQueryVariables>(
    GetControllerByIdDocument,
    options,
  );
}
export type GetControllerByIdQueryHookResult = ReturnType<typeof useGetControllerByIdQuery>;
export type GetControllerByIdLazyQueryHookResult = ReturnType<typeof useGetControllerByIdLazyQuery>;
export type GetControllerByIdQueryResult = Apollo.QueryResult<
  GetControllerByIdQuery,
  GetControllerByIdQueryVariables
>;
export function refetchGetControllerByIdQuery(variables: GetControllerByIdQueryVariables) {
  return { query: GetControllerByIdDocument, variables };
}
