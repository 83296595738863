// src/components/EditController/components/OpenControllerSection.tsx

import React, { useEffect } from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ConfigureFormValues,
  configureSchema,
} from '../../CreateNewControllerWizardV2/steps/ConfigureStep/validation';
import { useWizard } from '../../CreateNewControllerWizardV2/context/WizardContext';
import { ControllerBasicInfo } from '../../CreateNewControllerWizardV2/steps/ConfigureStep/components/ControllerBasicInfo';
import { ActionConfig } from '../../CreateNewControllerWizardV2/steps/ConfigureStep/components/ActionConfig';
import { SchedulingConfig } from '../../CreateNewControllerWizardV2/steps/ConfigureStep/components/SchedulingConfig';
import { WidgetPreview } from '../../CreateNewControllerWizardV2/shared/WidgetPreview';
import { ThemeColorConfig } from '../../CreateNewControllerWizardV2/steps/StyleStep/components/ThemeColorConfig';

export const OpenControllerSection: React.FC = () => {
  const { state, dispatch } = useWizard();

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setValue,
    trigger,
  } = useForm<ConfigureFormValues>({
    resolver: yupResolver(configureSchema),
    defaultValues: {
      name: state.draft?.name || '',
      actionType: state.draft?.stages[0]?.actions[0]?.type as 'emailCollection' | 'phoneCollection',
      action: {
        buttonLabel: state.draft?.stages[0]?.actions[0]?.label || '',
        instructionsText:
          state.draft?.stages[0]?.actions[0]?.emailCollection?.instructionsText ||
          state.draft?.stages[0]?.actions[0]?.phoneCollection?.instructionsText ||
          '',
        disclaimerText:
          state.draft?.stages[0]?.actions[0]?.emailCollection?.disclaimerText ||
          state.draft?.stages[0]?.actions[0]?.phoneCollection?.disclaimerText ||
          '',
        validationPattern:
          state.draft?.stages[0]?.actions[0]?.phoneCollection?.validationPattern || '',
      },
      scheduling: {
        isAllTime: !state.draft?.validFrom && !state.draft?.validUntil,
        validFrom: state.draft?.validFrom || null,
        validUntil: state.draft?.validUntil || null,
      },
      theme: {
        colors: {
          primary: state.draft?.layout.widget?.theme?.colors?.primary || '#6366f1',
        },
      },
    },
    mode: 'onChange',
    // reValidateMode: 'onChange',
  });

  // Watch individual fields
  const name = watch('name');
  const buttonLabel = watch('action.buttonLabel');
  const disclaimerText = watch('action.disclaimerText');
  const isAllTime = watch('scheduling.isAllTime');
  const validFrom = watch('scheduling.validFrom');
  const validUntil = watch('scheduling.validUntil');

  // Update draft
  useEffect(() => {
    if (!state.draft) return;

    const actionType = watch('actionType');
    const currentAction = state.draft.stages[0].actions[0];

    // Create updated action based on type
    let updatedAction: any;
    if (actionType === 'emailCollection') {
      updatedAction = {
        ...currentAction,
        type: 'emailCollection',
        label: buttonLabel,
        emailCollection: {
          ...(currentAction.emailCollection || {}),
          instructionsText: watch('action.instructionsText') || '',
          disclaimerText,
        },
      };
    } else {
      // phoneCollection
      updatedAction = {
        ...currentAction,
        type: 'phoneCollection',
        label: buttonLabel,
        phoneCollection: {
          ...(currentAction.phoneCollection || {}),
          instructionsText: watch('action.instructionsText') || '',
          disclaimerText,
          validationPattern: watch('action.validationPattern') || '',
        },
      };
    }

    const updatedDraft = {
      ...state.draft,
      name,
      validFrom: isAllTime ? null : validFrom,
      validUntil: isAllTime ? null : validUntil,
      layout: {
        ...state.draft.layout,
        widget: {
          ...state.draft.layout.widget,
          theme: {
            ...state.draft.layout.widget.theme,
            colors: {
              ...state.draft.layout.widget.theme?.colors,
              primary: watch('theme.colors.primary'),
            },
          },
        },
      },
      stages: state.draft.stages.map((stage) => ({
        ...stage,
        actions: [updatedAction],
      })),
    };

    // Direct draft update for immediate UI feedback
    dispatch({ type: 'SET_DRAFT', payload: updatedDraft });
  }, [
    name,
    buttonLabel,
    disclaimerText,
    isAllTime,
    validFrom,
    validUntil,
    watch('theme.colors.primary'),
    watch('actionType'),
    watch('action.instructionsText'),
    watch('action.validationPattern'),
  ]);

  // Track form validation state
  useEffect(() => {
    dispatch({
      type: 'SET_FORM_HANDLERS',
      payload: {
        configure: {
          handleSubmit: handleSubmit((data) => Promise.resolve(data)),
          isSubmitting,
          isValid,
        },
      },
    });

    return () => {
      dispatch({
        type: 'SET_FORM_HANDLERS',
        payload: {
          configure: undefined,
        },
      });
    };
  }, [isSubmitting, isValid, handleSubmit]);

  const previewState = {
    draftController: state.draft ?? undefined,
    controllerId: state.controllerId!,
    previewKey: state.previewKey,
    isSaving: state.isSaving,
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Stack spacing={3}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <ControllerBasicInfo errors={errors} register={register} />
          </Paper>

          {/* Theme Color */}
          {/* <Paper elevation={3} sx={{ p: 3 }}>
            <ThemeColorConfig setValue={setValue} />
          </Paper> */}

          <Paper elevation={3} sx={{ p: 3 }}>
            <SchedulingConfig
              control={control}
              errors={errors}
              register={register}
              setValue={setValue}
            />
          </Paper>

          <Paper elevation={3} sx={{ p: 3 }}>
            <ActionConfig errors={errors} register={register} setValue={setValue} />
          </Paper>
        </Stack>
      </Grid>

      <Grid item md={6} xs={12}>
        <Paper
          elevation={3}
          sx={{
            p: 3,
            position: 'sticky',
            top: 24,
            height: 'calc(100vh - 200px)',
            overflow: 'hidden',
          }}
        >
          <WidgetPreview fullscreenMode previewState={previewState} />
        </Paper>
      </Grid>
    </Grid>
  );
};
