import { useState, useEffect, useRef } from 'react';
import { useCustomerSettings } from '../contexts/CustomerSettingsContext';
import { usePatchCustomerSettingsMutation } from './api/patchCustomerSettings/patchCustomerSettings.generated';
import { useCustomerSettingsQuery } from './api/customerSettings/customerSettings.generated';
import { appConfig } from '../app-config';

export interface CrmBridgeConfig {
  enabled: boolean;
  apiKey: string;
  crmBridgeKey: string;
}

export interface SnackbarMessage {
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
}

export const useCrmBridge = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [config, setConfig] = useState<CrmBridgeConfig>({
    enabled: false,
    apiKey: appConfig.crmBridge.apiKey,
    crmBridgeKey: '',
  });
  const updatingRef = useRef(false);

  // Use the same GraphQL mutations and queries as UpdateCustomerSettingsForm
  const [patchCustomerSettings, { loading: mutationLoading }] = usePatchCustomerSettingsMutation();
  const { data, loading: queryLoading, refetch } = useCustomerSettingsQuery({});

  const customerSettingsMethods = useCustomerSettings();

  // Get current crmBridgeKey from the query data
  const currentKey = data?.customerSettings?.crmBridgeKey || '';
  const isConnected = !!currentKey;

  // Update the config based on fetched data - Fixed: Changed from useState to useEffect
  useEffect(() => {
    if (data && !updatingRef.current) {
      setConfig((prev) => ({
        ...prev,
        enabled: !!data.customerSettings?.crmBridgeKey,
        crmBridgeKey: data.customerSettings?.crmBridgeKey || '',
      }));
      setIsLoading(false);
    }
  }, [data]);

  /**
   * Save the CRM Bridge key to customer settings using patchCustomerSettings
   * Following the same pattern as UpdateCustomerSettingsForm
   */
  const handleConnectionComplete = async (crmBridgeKey: string) => {
    if (!crmBridgeKey) {
      setError('Bridge key cannot be empty');
      return false;
    }

    try {
      updatingRef.current = true;
      setIsLoading(true);

      // Use patchCustomerSettings to save the key to customer settings
      await patchCustomerSettings({
        variables: {
          input: {
            crmBridgeKey,
          },
        },
      });

      // Update local state with the new key
      setConfig((prev) => ({
        ...prev,
        crmBridgeKey,
        enabled: true,
      }));

      // Refetch the data to get the updated settings
      await refetch();

      setError(null);
      console.log('CRM connection setup completed successfully', 'success');
      return true;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to connect CRM Bridge';
      setError(errorMessage);
      console.log('Failed to save CRM Bridge key', 'error');
      return false;
    } finally {
      setIsLoading(false);
      // Allow updates from data changes again after a short delay
      setTimeout(() => {
        updatingRef.current = false;
      }, 500);
    }
  };

  /**
   * Disconnect CRM Bridge by setting crmBridgeKey to null
   */
  const handleDisconnect = async (): Promise<boolean> => {
    try {
      setIsLoading(true);

      // Set crmBridgeKey to null to remove it
      await patchCustomerSettings({
        variables: {
          input: {
            crmBridgeKey: null,
          },
        },
      });

      // Update local state
      setConfig((prev) => ({
        ...prev,
        crmBridgeKey: '',
        enabled: false,
      }));

      // Refetch to get updated data
      await refetch();

      setError(null);
      console.log('CRM Bridge disconnected', 'success');
      return true;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to disconnect CRM Bridge';
      setError(errorMessage);
      console.log('Failed to disconnect CRM Bridge', 'error');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    config,
    isLoading: isLoading || queryLoading || mutationLoading,
    isConnected,
    currentKey,
    error,
    userId: customerSettingsMethods.customer?.id || '',
    handleConnectionComplete,
    handleDisconnect,
  };
};
