import { useEffect, useState } from 'react';

// Track loaded scripts to prevent duplicate loading
const loadedScripts: Record<string, boolean> = {};

type ScriptStatus = 'idle' | 'loading' | 'ready' | 'error';

interface ScriptOptions {
  esmSrc?: string;
  legacySrc?: string;
}

/**
 * Hook to load external scripts following the modern ES Module pattern
 *
 * Can be used in two ways:
 * 1. With a single src string: useExternalScript('https://example.com/script.js')
 * 2. With ESM/legacy pair: useExternalScript({ esmSrc: '...esm.js', legacySrc: '...js' })
 */
export function useExternalScript(source: string | ScriptOptions): ScriptStatus {
  // Handle different input formats
  const isModernPattern = typeof source !== 'string';
  const primarySrc = typeof source === 'string' ? source : source.esmSrc || '';
  const legacySrc = isModernPattern ? source.legacySrc || '' : '';

  // Set initial status based on whether scripts were already loaded
  const initialStatus = loadedScripts[primarySrc] ? 'ready' : 'idle';
  const [status, setStatus] = useState<ScriptStatus>(initialStatus);

  useEffect(() => {
    // Skip if the script is already loaded
    if (loadedScripts[primarySrc]) {
      setStatus('ready');
      return undefined;
    }

    // For modern pattern, load both scripts with appropriate attributes
    if (isModernPattern && primarySrc && legacySrc) {
      // Create module script (modern browsers)
      const moduleScript = document.createElement('script');
      moduleScript.type = 'module';
      moduleScript.src = primarySrc;
      moduleScript.async = true;

      // Create nomodule script (older browsers)
      const legacyScript = document.createElement('script');
      legacyScript.src = legacySrc;
      legacyScript.async = true;
      legacyScript.setAttribute('nomodule', '');

      // Set loading status and mark scripts as loading
      setStatus('loading');
      loadedScripts[primarySrc] = true;
      loadedScripts[legacySrc] = true;

      // Handle script load/error events
      const handleModuleLoad = (e: Event) => {
        if (e.type === 'load') {
          setStatus('ready');
        } else {
          // Only set error if both scripts fail
          setStatus((prev) => (prev === 'error' ? 'error' : 'loading'));
        }
      };

      const handleLegacyLoad = (e: Event) => {
        if (e.type === 'load') {
          setStatus('ready');
        } else {
          // Only set error if both scripts fail
          setStatus((prev) => (prev === 'error' ? 'error' : 'loading'));
        }
      };

      // Add event listeners
      moduleScript.addEventListener('load', handleModuleLoad);
      moduleScript.addEventListener('error', handleModuleLoad);
      legacyScript.addEventListener('load', handleLegacyLoad);
      legacyScript.addEventListener('error', handleLegacyLoad);

      // Add scripts to document
      document.body.appendChild(moduleScript);
      document.body.appendChild(legacyScript);

      // Clean up
      return () => {
        moduleScript.removeEventListener('load', handleModuleLoad);
        moduleScript.removeEventListener('error', handleModuleLoad);
        legacyScript.removeEventListener('load', handleLegacyLoad);
        legacyScript.removeEventListener('error', handleLegacyLoad);
      };
    }
    // Legacy approach - single script
    if (primarySrc) {
      // Create script element
      const script = document.createElement('script');

      // If the source includes .esm. set it as a module
      if (primarySrc.includes('.esm.')) {
        script.type = 'module';
      }

      script.src = primarySrc;
      script.async = true;

      // Set loading status
      setStatus('loading');
      loadedScripts[primarySrc] = true;

      // Script event handler to update status
      const handleScript = (e: Event) => {
        setStatus(e.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);

      // Add script to document
      document.body.appendChild(script);

      // Clean up
      return () => {
        script.removeEventListener('load', handleScript);
        script.removeEventListener('error', handleScript);
      };
    }

    return undefined;
  }, [primarySrc, legacySrc, isModernPattern]);

  return status;
}
